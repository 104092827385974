import {
  Stack,
  Typography,
  OutlinedInput,
  FormControl,
  FormHelperText,
} from '@mui/material';
import React, { ChangeEvent, FC, useCallback } from 'react';
import Icon from '@/components/ui/Icon';
import { TextInputProps } from '@/components/ui/TextInput';

import style from './style';

export interface TextInputInlineProps extends TextInputProps {
  subtitle?: string;
  labelFixedWidth?: string;
}

const TextInputInline: FC<TextInputInlineProps> = ({
  label,
  subtitle,
  placeholder,
  onTextChange,
  type,
  value,
  error,
  touched,
  icon,
  hideErrorMessage,
  labelFixedWidth,
  ...props
}) => {
  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      onTextChange?.(e.target.value);
    },
    [onTextChange],
  );

  return (
    <FormControl style={style.container} error={!!error}>
      <Stack flexDirection="row" alignItems="center">
        <Stack width={labelFixedWidth ? labelFixedWidth : '304px'} gap={2}>
          {!!label && (
            <Typography
              variant="body1"
              sx={style.label}
              color={error ? 'error.main' : 'text.secondary'}
            >
              {label}
            </Typography>
          )}
          {!!subtitle && (
            <Typography
              variant="body2"
              color={error ? 'error.main' : 'text.primary'}
            >
              {subtitle}
            </Typography>
          )}
        </Stack>
        <Stack flex={1}>
          <OutlinedInput
            value={value || ''}
            placeholder={placeholder}
            onChange={handleChange}
            size="small"
            aria-describedby="field-text-input-descriptor"
            startAdornment={
              icon && <Icon sx={style.icon} icon={icon} size={24} />
            }
            {...props}
          />
          {!hideErrorMessage && (
            <FormHelperText sx={style.error} id="field-text-input-descriptor">
              {error}
            </FormHelperText>
          )}
        </Stack>
      </Stack>
    </FormControl>
  );
};

export default TextInputInline;
