import { SXStyleSheet } from '@/utils';
import { tableCellClasses } from '@mui/material';

const style = SXStyleSheet.create({
  container: {
    backgroundColor: '#F9FAFB',
    height: '44px',
    [`.${tableCellClasses.root}`]: {
      padding: 0,
      px: 6,
      fontSize: '12px',
      backgroundColor: '#F9FAFB',
    },
  },
});

export default style;
