import auth from './auth';
import user from './user';
import customer from './customer';

const api = {
  auth,
  user,
  customer,
};

export default api;
