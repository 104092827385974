import SingleSelect, { SingleSelectProps } from '@/components/ui/SingleSelect';
import { FC } from 'react';
import useFormField from '@/hooks/useFormField';

export interface SelectFieldProps
  extends Omit<SingleSelectProps, 'value' | 'error'> {
  fieldName: string;
  // @todo(KAN-119): investigate how we are going to clear values on change the answer
  onSelect?: (value: string) => void;
}

const SelectField: FC<SelectFieldProps> = ({
  fieldName,
  onSelect,
  ...props
}) => {
  const { value, error, setValue } = useFormField<string>(fieldName);

  const handleSelect = (value: string) => {
    onSelect ? onSelect(value) : setValue(value);
  };

  return (
    <SingleSelect
      value={value}
      error={error}
      onSelect={handleSelect}
      {...props}
    />
  );
};

export default SelectField;
