import { useTranslation } from 'react-i18next';
import { FC } from 'react';

import NewUserForm, { NewUserFormValues } from '@/components/forms/NewUserForm';
import Modal from '@/components/ui/Modal';

export interface NewUserDialogProps {
  open: boolean;
  onSubmit: (values: NewUserFormValues) => Promise<void>;
  onClose: () => void;
  error?: string | false;
}

const NewUserDialog: FC<NewUserDialogProps> = ({
  open,
  onSubmit,
  onClose,
  error,
}) => {
  const { t } = useTranslation();

  return (
    <Modal
      open={open}
      title={t('admin:newUser.title')}
      subtitle={t('admin:newUser.subtitle')}
      onClose={onClose}
      icon="EditLarge"
    >
      <NewUserForm error={error} onSubmit={onSubmit} onCancel={onClose} />
    </Modal>
  );
};

export default NewUserDialog;
