import { SXStyleSheet } from '@/utils';

const style = SXStyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    py: 4,
    px: 6,
    gap: 4,
    cursor: 'pointer',
  },
  nextStep: {
    opacity: 0,
  },
  nextStepActive: {
    opacity: 1,
  },
  titleContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    opacity: 0.5,
  },
  titleContainerActive: {
    opacity: 1,
  },
  titleContainerDone: {
    opacity: 0.7,
  },
  titleStepActive: {
    opacity: 1,
  },
});

export default style;
