import { SXStyleSheet } from '@/utils';

const style = SXStyleSheet.create({
  disabled: {
    opacity: 0.2,
  },
  icon: {},
  outlined: {
    width: '40px',
    height: '40px',
    borderRadius: 2,
    border: '1px solid #EBEDF1',
  },
});

export default style;
