import {
  ApiResponse,
  AuthResponse,
  Credentials,
  ForgotPasswordRequest,
  ResetPasswordRequest,
} from '@/types';
import network, { initializeAuthSession } from '@/services/network';
import { ErrorResponse } from '@/utils';
import { validateResponseObject } from '@/utils/validation';

const signIn = async (credentials: Credentials): Promise<AuthResponse> => {
  try {
    const {
      data: { data },
    } = await network.post<ApiResponse<AuthResponse>>(
      '/auth/sign-in',
      credentials,
    );

    validateResponseObject(data, 'accessToken', 'tokenType');

    initializeAuthSession(data.accessToken);

    return data;
  } catch (error) {
    throw new ErrorResponse(error);
  }
};

const forgotPassword = async (data: ForgotPasswordRequest): Promise<void> => {
  try {
    await network.post('/auth/forgot-password', data);
  } catch (error) {
    throw new ErrorResponse(error);
  }
};

const resetPassword = async (data: ResetPasswordRequest): Promise<void> => {
  try {
    await network.post('/auth/reset-password', data);
  } catch (error) {
    throw new ErrorResponse(error);
  }
};

const auth = {
  signIn,
  forgotPassword,
  resetPassword,
};

export default auth;
