import { Dialog, Stack, Typography } from '@mui/material';
import { ComponentType, FC, PropsWithChildren } from 'react';
import Icon from '@/components/ui/Icon';
import { IconType } from '@/assets/icons';
import style from './style';
import IconButton from '@/components/ui/IconButton';

export interface ModalProps {
  open: boolean;
  onClose: () => void;
  title: string;
  subtitle?: string;
  icon?: IconType;
  ActionArea?: ComponentType;
}

const Modal: FC<PropsWithChildren<ModalProps>> = ({
  open,
  onClose,
  icon,
  subtitle,
  title,
  children,
  ActionArea,
}) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <Stack sx={style.container}>
        <Stack sx={style.iconGroup}>
          {!!icon && <Icon icon={icon} />}
          <IconButton icon="Close" onClick={onClose} />
        </Stack>
        <Stack>
          <Typography variant="subtitle2">{title}</Typography>
          {!!subtitle && <Typography variant="body2">{subtitle}</Typography>}
        </Stack>
        <Stack>{children}</Stack>
      </Stack>
      {!!ActionArea && <ActionArea />}
    </Dialog>
  );
};

export default Modal;
