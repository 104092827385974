import { SXStyleSheet } from '@/utils';

const style = SXStyleSheet.create({
  container: (theme) => ({
    width: '311px',
    height: '100%',
    justifyContent: 'space-between',
    borderRight: `1px solid ${theme.palette.divider}`,
    px: 4,
    py: 8,
    alignItems: 'flex-start',
  }),
  image: {
    width: '100%',
  },
  itemsContainer: {
    width: '100%',
    flex: 1,
    pt: 11,
  },
  noLogo: {
    pt: 7,
  },
});

export default style;
