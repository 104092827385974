import { Formik } from 'formik';
import { Stack, Typography } from '@mui/material';
import TextField from '@/components/formControl/TextField';
import { useTranslation } from 'react-i18next';
import CTAButton from '@/components/ui/CTAButton';
import CheckBoxField from '@/components/formControl/CheckBoxField';
import { CreateUserRequest } from '@/types';
import { FC } from 'react';
import SubmitButton from '@/components/formControl/SubmitButton';
import { newUserValidationSchema } from '@/constants/validation';

export type NewUserFormValues = Omit<
  CreateUserRequest,
  'password_confirmation'
>;

const initialValues: NewUserFormValues = {
  name: '',
  email: '',
  password: '',
  role: 'consultant',
};

export interface NewUserFormProps {
  onSubmit: (values: NewUserFormValues) => void;
  onCancel: () => void;
  error?: string | false;
}

const NewUserForm: FC<NewUserFormProps> = ({ onCancel, onSubmit, error }) => {
  const { t } = useTranslation();

  return (
    <Formik
      validationSchema={newUserValidationSchema}
      initialValues={initialValues}
      onSubmit={onSubmit}
      validateOnMount={true}
      isInitialValid={false}
    >
      <Stack gap={5}>
        <TextField
          fieldName="name"
          hideErrorMessage
          label={t('admin:newUser.formLabels.name')}
          placeholder={t('admin:newUser.placeholders.name')}
        />
        <TextField
          hideErrorMessage
          fieldName="email"
          label={t('admin:newUser.formLabels.email')}
          placeholder={t('admin:newUser.placeholders.email')}
        />
        <TextField
          hideErrorMessage
          fieldName="password"
          type="password"
          label={t('admin:newUser.formLabels.password')}
          placeholder={t('admin:newUser.placeholders.password')}
        />
        <CheckBoxField
          fieldName="role"
          options={['consultant', 'admin']}
          label={t('admin:newUser.formLabels.role')}
        />
        {error && (
          <Typography variant="body2" color="error.main">
            {error}
          </Typography>
        )}
        <Stack direction="row" gap={4}>
          <CTAButton
            label={t('admin:newUser.actions.cancel')}
            variant="outlined"
            onClick={onCancel}
          />
          <SubmitButton label={t('admin:newUser.actions.save')} />
        </Stack>
      </Stack>
    </Formik>
  );
};

export default NewUserForm;
