import { SXStyleSheet } from '@/utils';

const style = SXStyleSheet.create({
  container: {
    p: 6,
    gap: 5,
  },
  iconGroup: {
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
    width: '100%',
  },
});

export default style;
