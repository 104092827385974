import { TabItem } from '@/components/ui/Tabs';

// @todo(KAN-119): connect with translations
export const CustomerTabItems: TabItem[] = [
  {
    label: 'Alle Clients',
    id: 'all-clients',
  },
  {
    label: 'In Bearbeitung',
    id: 'in-work',
  },
  {
    label: 'Aufgeschoben',
    id: 'pending',
  },
];

export enum NewCustomerTab {
  Client = 'client',
  Partner = 'partner',
  Children = 'children',
}

export const NewCustomerTabItems: TabItem[] = [
  {
    label: 'customer:newCustomer.tabs.client',
    id: NewCustomerTab.Client,
  },
  {
    label: 'customer:newCustomer.tabs.partner',
    id: NewCustomerTab.Partner,
  },
  {
    label: 'customer:newCustomer.tabs.children',
    id: NewCustomerTab.Children,
  },
];
