import { Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useCallback, useEffect, useState } from 'react';

import CustomerHeader from '@/components/CustomerHeader';
import Tabs from '@/components/ui/Tabs';
import Badge from '@/components/ui/Badge';
import Pagination from '@/components/ui/Pagination';
import Table from '@/components/ui/Table';
import CustomerTableRaw from '@/components/ui/CustomerTableRow';

import useAuthStore from '@/store/auth';
import useCustomerStore from '@/store/customers';
import { Customer } from '@/types';
import { CustomerTableColumns } from '@/constants/table';
import { CustomerTabItems } from '@/constants/tabs';
import api from '@/services/api';
import { useNavigate } from 'react-router-dom';
import { getPath, getPathQuery } from '@/utils';
import { Routes } from '@/constants/routes';

const CustomersView = () => {
  // @todo(KAN-119): update magic 7 with device screen height auto adjustments
  const [tableDataRows] = useState(7);
  const [creatingCustomer, setCreatingCustomer] = useState(false);

  const { t } = useTranslation();
  const navigate = useNavigate();

  const { user } = useAuthStore();
  const { customers, pagination, prevPage, nextPage, fetchCustomers } =
    useCustomerStore();

  const getTableData = useCallback(async () => {
    fetchCustomers(tableDataRows).catch(console.error);
  }, [fetchCustomers, tableDataRows]);

  useEffect(() => {
    getTableData().catch();
  }, [getTableData]);

  const handleCustomerClick = (id: number) => {
    // @todo: should navigate to customer data instead of edit
    navigate(
      getPathQuery(getPath('..', Routes.Customers, Routes.NewCustomer), {
        id,
      }),
    );
  };

  const renderTableRow = (item: Customer) => {
    return (
      <CustomerTableRaw
        onCustomerClick={handleCustomerClick}
        data={item as Customer}
        key={item.id}
      />
    );
  };

  const handleCreateCustomer = async () => {
    setCreatingCustomer(true);
    try {
      const { id } = await api.customer.createCustomer();
      navigate(
        getPathQuery(getPath('..', Routes.Customers, Routes.NewCustomer), {
          id,
        }),
      );
    } catch (e) {
      console.error(e);
    } finally {
      setCreatingCustomer(false);
    }
  };

  const renderTableTitle = useCallback(() => {
    return (
      <Stack>
        <Stack direction="row" alignItems="center" gap={2} px={6} pb={5}>
          <Typography variant="subtitle1">
            {t('customer:home.customerTable.title')}
          </Typography>
          <Badge
            text={t('customer:home.customerTable.userCount', {
              count: pagination?.total,
            })}
          />
        </Stack>
        <Stack px={3}>
          <Tabs activeTab={'all-clients'} items={CustomerTabItems} />
        </Stack>
      </Stack>
    );
  }, [pagination?.total, t]);

  return (
    <Stack flex={1}>
      <CustomerHeader
        username={user?.name || ''}
        onAddClick={handleCreateCustomer}
        loading={creatingCustomer}
        filterItems={['Alle Zeit', 'Neu']}
      />
      <Stack flex={1} px={10} overflow="hidden">
        <Table
          data={customers || []}
          renderRow={renderTableRow}
          renderTableTitle={renderTableTitle}
          headerItems={CustomerTableColumns}
        />
        <Pagination
          page={pagination?.current_page || 0}
          total={pagination?.last_page || 0}
          nextPage={nextPage}
          prevPage={prevPage}
        />
      </Stack>
    </Stack>
  );
};

export default CustomersView;
