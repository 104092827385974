import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from '@mui/material';
import React, { ChangeEvent, FC } from 'react';
import { map } from 'lodash';

import { SingleSelectItem } from '@/components/ui/SingleSelect';

import style from './style';

export interface RadioSelectProps {
  value: string | boolean;
  options: SingleSelectItem[];
  label?: string;
  subtitle?: string;
  error?: string | null;
  onSelect?: (value: string) => void;
}

const RadioSelect: FC<RadioSelectProps> = ({
  value,
  options,
  label,
  subtitle,
  error,
  onSelect,
}) => {
  const renderRadioGroupItem = (item: SingleSelectItem) => {
    return (
      <FormControlLabel
        key={item.value}
        control={<Radio />}
        label={item.label}
        value={item.value}
      />
    );
  };

  const handleChange = (_: ChangeEvent<HTMLInputElement>, value: string) => {
    onSelect?.(value);
  };

  return (
    <Stack style={style.container}>
      <Stack width="304px" gap={2}>
        {!!label && (
          <Typography
            variant="body1"
            sx={style.label}
            color={error ? 'error.main' : 'text.secondary'}
          >
            {label}
          </Typography>
        )}
        {!!subtitle && (
          <Typography
            variant="body2"
            color={error ? 'error.main' : 'text.primary'}
          >
            {subtitle}
          </Typography>
        )}
      </Stack>
      <Stack>
        <FormControl>
          <RadioGroup
            sx={style.radioGroup}
            value={value}
            onChange={handleChange}
          >
            {map(options, renderRadioGroupItem)}
          </RadioGroup>
        </FormControl>
      </Stack>
    </Stack>
  );
};

export default RadioSelect;
