import {
  SingleSelectGroupItem,
  SingleSelectItem,
} from '@/components/ui/SingleSelect';

export const isSelectGroup = (
  item: SingleSelectItem | SingleSelectGroupItem,
): item is SingleSelectGroupItem => {
  return !!(item as SingleSelectGroupItem).items;
};
