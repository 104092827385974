import { Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import TextInput from '@/components/ui/TextInput';
import CTAButton from '@/components/ui/CTAButton';
import React, { FC, useState } from 'react';
import { split } from 'lodash';

export interface HomeHeaderProps {
  username: string;
  onAddClick: () => void;
}

const AdminHeader: FC<HomeHeaderProps> = ({ username, onAddClick }) => {
  // @todo(KAN-111): implement search
  const [searchValue, setSearchValue] = useState('');

  const { t } = useTranslation();

  return (
    <Stack px={10} py={8} gap={8}>
      <Stack gap={1}>
        <Typography variant="h1" color="text.secondary">
          {t('admin:home.title', { name: split(username, ' ')[0] })}
        </Typography>
        <Typography>{t('admin:home.subtitle')}</Typography>
      </Stack>
      <Stack direction="row" justifyContent="space-between">
        <TextInput
          icon="Search"
          value={searchValue}
          onTextChange={setSearchValue}
          placeholder={t('admin:home.searchPlaceholder')}
          autoWidth
        />
        <CTAButton
          icon="Plus"
          label={t('admin:home.addUser')}
          variant="secondary"
          onClick={onAddClick}
          autoWidth
        />
      </Stack>
    </Stack>
  );
};

export default AdminHeader;
