import { FC, PropsWithChildren } from 'react';

import { SessionProvider } from '@/utils/context/SessionContext';
import useAuthStore from '@/store/auth';

const Session: FC<PropsWithChildren> = ({ children }) => {
  const { user } = useAuthStore();

  return (
    <SessionProvider value={{ authenticated: !!user?.accessToken }}>
      {children}
    </SessionProvider>
  );
};

export default Session;
