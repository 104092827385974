import { TableCell, TableRow as MUITableRow, Typography } from '@mui/material';
import User from '@/components/ui/User';
import LinkButton from '@/components/ui/LinkButton';
import IconButton from '@/components/ui/IconButton';
import { FC, useState } from 'react';

import style from './style';
import { useTranslation } from 'react-i18next';
import { User as UserType } from '@/types/user';
import { getTranslationKey } from '@/utils/system';

export interface AdminTableRawProps {
  data: UserType;
}

const UserTableRow: FC<AdminTableRawProps> = ({ data }) => {
  const [activeRow, setActiveRow] = useState(false);
  const { t } = useTranslation();

  const handleMouse = (over: boolean) => () => {
    setActiveRow(over);
  };

  return (
    <MUITableRow
      sx={style.row}
      onMouseEnter={handleMouse(true)}
      onMouseLeave={handleMouse(false)}
    >
      <TableCell>
        <User name={data.name} />
      </TableCell>
      <TableCell>
        <Typography>{data.email}</Typography>
      </TableCell>
      <TableCell>
        <Typography>
          {t(
            getTranslationKey(
              `common:roles.${data.role}`,
              'errors:custom.noRole',
            ),
          )}
        </Typography>
      </TableCell>
      <TableCell>
        <LinkButton
          size="large"
          text={t('common:listOfClients')}
          textAlignment="left"
        />
      </TableCell>
      <TableCell width={81}>
        <IconButton icon="Edit" disabled={!activeRow} />
      </TableCell>
    </MUITableRow>
  );
};

export default UserTableRow;
