import { SXStyleSheet } from '@/utils';

const style = SXStyleSheet.create({
  container: {
    flexDirection: 'row',
    gap: 3,
  },
  clearAll: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 2,
  },
  filterItem: {
    borderWidth: 1,
    borderStyle: 'solid',
    borderRadius: 2,
    borderColor: 'divider',
    flexDirection: 'row',
    gap: 2,
    height: '40px',
    flexWrap: 'nowrap',
    px: 4,
    alignItems: 'center',
    width: 'fit-content',
  },
});

export default style;
