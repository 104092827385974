import { Stack, Typography } from '@mui/material';
import { FC } from 'react';
import CTAButton from '@/components/ui/CTAButton';

export interface NewCustomerHeaderProps {
  title: string;
  subtitle: string;
  nextLabel: string;
  saveLabel: string;
  prevDisabled?: boolean;
  nextDisabled?: boolean;
  finalStep?: boolean;
  onNext?: () => void;
  onBack?: () => void;
  onSave?: () => void;
}

const NewCustomerHeader: FC<NewCustomerHeaderProps> = ({
  title,
  subtitle,
  nextLabel,
  saveLabel,
  prevDisabled,
  finalStep,
  onBack,
  onNext,
  onSave,
}) => {
  return (
    <Stack
      px={8}
      pt="64px"
      pb="20px"
      flexDirection="row"
      justifyContent="space-between"
    >
      <Stack>
        <Typography variant="h1" color="text.secondary">
          {title}
        </Typography>
        <Typography variant="body1">{subtitle}</Typography>
      </Stack>
      <Stack flexDirection="row" gap={6}>
        {!prevDisabled && (
          <CTAButton
            icon="Prev"
            variant="outlined"
            size="large"
            fixedWidth={56}
            disabled={prevDisabled}
            onClick={onBack}
          />
        )}
        {!finalStep ? (
          <CTAButton
            label={nextLabel}
            size="large"
            fixedWidth={156}
            icon="Next"
            variant="outlined"
            onClick={onNext}
          />
        ) : (
          <CTAButton
            label={saveLabel}
            fixedWidth={156}
            variant="secondary"
            size="large"
            onClick={onSave}
          />
        )}
      </Stack>
    </Stack>
  );
};

export default NewCustomerHeader;
