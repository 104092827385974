import { Typography } from '@mui/material';
import { FC } from 'react';

import style from './style';
import { SXStyleSheet } from '@/utils';

export interface LinkButtonProps {
  text: string;
  href?: string;
  size?: 'small' | 'large';
  onClick?: () => void;
  textAlignment?: 'left' | 'center' | 'right';
}

const LinkButton: FC<LinkButtonProps> = ({
  text,
  size = 'small',
  onClick,
  href,
  textAlignment = 'center',
}) => {
  return (
    <Typography
      component={'a'}
      href={href}
      sx={SXStyleSheet.merge(style.text, style.textVariants[size])}
      variant="body1"
      color="primary.main"
      textAlign={textAlignment}
      onClick={onClick}
    >
      {text}
    </Typography>
  );
};

export default LinkButton;
