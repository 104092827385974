export const Routes = {
  Root: '/',
  Home: 'home',
  Auth: 'auth',
  SignIn: 'sign-in',
  ResetPasswordEmail: 'reset-password-email',
  ResetPasswordSuccess: 'reset-password-success',
  PasswordReset: 'password-reset',
  ForgotPassword: 'forgot-password',
  Admin: 'admin',
  Customers: 'customers',
  NewCustomer: 'new-customer',
};

export const CompanyRoute = 'https://softverysolutions.com';
