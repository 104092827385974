import { Formik } from 'formik';
import { Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import SubmitButton from '@/components/formControl/SubmitButton';
import TextField from '@/components/formControl/TextField';
import LinkButton from '@/components/ui/LinkButton';

import { Credentials } from '@/types';
import { signInValidationSchema } from '@/constants/validation';
import { FC } from 'react';

const initialValues: Credentials = {
  email: '',
  password: '',
};

export interface SignInFormProps {
  onSubmit: (values: Credentials) => Promise<void>;
  onForgotPasswordClick: () => void;
  error?: string | false;
  onResetError?: () => void;
}

const SignInForm: FC<SignInFormProps> = ({
  onSubmit,
  error,
  onForgotPasswordClick,
  onResetError,
}) => {
  const { t } = useTranslation();

  return (
    <Formik
      validationSchema={signInValidationSchema}
      initialValues={initialValues}
      validateOnMount={true}
      isInitialValid={false}
      onSubmit={onSubmit}
    >
      <Stack gap={6}>
        <Stack gap={5}>
          <TextField
            fieldName="email"
            onFocus={onResetError}
            label={t('auth:signIn.labels.email')}
            placeholder={t('auth:signIn.placeholders.email')}
          />
          <TextField
            fieldName="password"
            onFocus={onResetError}
            label={t('auth:signIn.labels.password')}
            placeholder={t('auth:signIn.placeholders.password')}
            type="password"
          />
          {error && (
            <Typography variant="body2" color="error.main">
              {error}
            </Typography>
          )}
        </Stack>
        <Stack gap={2}>
          <SubmitButton label={t('auth:signIn.actions.signIn')} />
          <LinkButton
            size="large"
            onClick={onForgotPasswordClick}
            text={t('auth:signIn.actions.forgotPassword')}
          />
        </Stack>
      </Stack>
    </Formik>
  );
};

export default SignInForm;
