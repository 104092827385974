import { Stack, Table as MUITable, TableBody } from '@mui/material';
import { map } from 'lodash';

import TableHeader, { TableHeaderItem } from '@/components/ui/TableHeader';

export interface TableProps<T> {
  data: T[];
  renderRow: (data: T, i: number) => JSX.Element | null;
  renderTableTitle?: () => JSX.Element;
  headerItems: TableHeaderItem[];
}

const Table = <T,>({
  data,
  renderRow,
  headerItems,
  renderTableTitle,
}: TableProps<T>) => {
  return (
    <Stack flex={1} overflow="hidden">
      {!!renderTableTitle && renderTableTitle()}
      <Stack flex={1} maxHeight="100%" maxWidth="100%" overflow="scroll">
        <MUITable stickyHeader>
          <TableHeader items={headerItems} />
          <TableBody>{map(data, renderRow)}</TableBody>
        </MUITable>
      </Stack>
    </Stack>
  );
};

export default Table;
