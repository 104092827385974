import { FC, useCallback } from 'react';
import { FieldArray } from 'formik';
import { map } from 'lodash';
import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';

import ChildForm, { childInitialValues } from '@/components/forms/ChildForm';
import TextButton from '@/components/ui/TextButton';

import { Child, CustomerFormProps } from '@/types';
import { getFormKey } from '@/utils/system';

const ChildrenForm: FC<CustomerFormProps> = ({ formKey }) => {
  const { t } = useTranslation();

  const renderChildForm = useCallback(
    (onDelete: (index: number) => void) => (_: Child, i: number) => {
      return (
        <ChildForm
          key={i}
          formKey={getFormKey(formKey, 'children')}
          index={i}
          onDelete={onDelete}
        />
      );
    },
    [formKey],
  );

  return (
    <Stack>
      <FieldArray
        name={getFormKey(formKey, 'children')}
        render={(arrayHelpers) => {
          return (
            <Stack gap={10}>
              {map(
                arrayHelpers.form.values[formKey]?.children,
                renderChildForm(arrayHelpers.remove),
              )}
              <Stack py={4} flexDirection="row" justifyContent="flex-start">
                <TextButton
                  text={t('customer:newCustomer.addChild')}
                  color="primary"
                  icon="AddPrimary"
                  onClick={() => {
                    arrayHelpers.push(childInitialValues);
                  }}
                />
              </Stack>
            </Stack>
          );
        }}
      />
    </Stack>
  );
};

export default ChildrenForm;
