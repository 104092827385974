import { TableCell, TableRow as MUITableRow, Typography } from '@mui/material';
import { FC } from 'react';

import style from './style';
import { Customer } from '@/types';
import { compact, join } from 'lodash';

export interface CustomerTableRawProps {
  data: Customer;
  onCustomerClick?: (id: number) => void;
}

const CustomerTableRaw: FC<CustomerTableRawProps> = ({
  data,
  onCustomerClick,
}) => {
  const name = join(compact([data.first_name, data.last_name]), ' ');

  const handleCustomerClick = () => {
    onCustomerClick?.(data.id);
  };

  return (
    <MUITableRow sx={style.row} onClick={handleCustomerClick}>
      <TableCell>
        <Typography>{name}</Typography>
      </TableCell>
      <TableCell>
        <Typography>{data.address}</Typography>
      </TableCell>
      <TableCell>
        <Typography>{data.postal_code}</Typography>
      </TableCell>
      <TableCell>
        <Typography>{data.city}</Typography>
      </TableCell>
      <TableCell>
        <Typography>{data.gross_salary}</Typography>
      </TableCell>
      <TableCell>
        <Typography>{data.net_salary}</Typography>
      </TableCell>
      <TableCell>
        <Typography>{data.additional_income_type || ''}</Typography>
      </TableCell>
      <TableCell>
        <Typography>{data.additional_income_amount || ''}</Typography>
      </TableCell>
      {/*@todo(KAN-85): height and weight not included in swagger but exists in UI*/}
      <TableCell>
        <Typography>{data.height || ''}</Typography>
      </TableCell>
      <TableCell>
        <Typography>{data.weight || ''}</Typography>
      </TableCell>
      <TableCell>
        <Typography>{data.highest_education}</Typography>
      </TableCell>
      <TableCell>
        <Typography>{data.rent_amount}</Typography>
      </TableCell>
    </MUITableRow>
  );
};

export default CustomerTableRaw;
