import { SXStyleSheet } from '@/utils';

const style = SXStyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    height: '40px',
    borderRadius: '6px',
    '&:hover': {
      backgroundColor: 'primary.light',
    },
  },
  containerActive: {
    backgroundColor: 'primary.light',
  },
  altColorActive: {
    backgroundColor: 'primary.main',
    '&:hover': {
      backgroundColor: 'primary.main',
    },
  },
  buttonContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    gap: 3,
    alignItems: 'center',
    cursor: 'pointer',
    justifyContent: 'flex-start',
    px: 3,
  },
  buttonContainerAltColor: {
    color: 'primary.contrastText',
  },
  iconActive: {
    rotate: '180deg',
  },
  subItemContainer: {
    paddingLeft: '56px',
  },
  subItemButtonContainer: {
    justifyContent: 'flex-start',
    alignItems: 'center',
    height: '44px',
  },
});

export default style;
