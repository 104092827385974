import { forwardRef, useImperativeHandle, useMemo, useState } from 'react';
import { CustomerSteps } from '@/constants/steppers';
import Stepper from '@/components/ui/Stepper';
import { findIndex } from 'lodash';
import ChildrenForm from '@/components/forms/ChildrenForm';
import { useTranslation } from 'react-i18next';

export interface ChildrenStepperProps {
  onStepperStart: () => void;
}

export interface ChildrenStepperRef {
  prevStep: () => void;
}

const Steps = {
  [CustomerSteps.PersonalDetails]: <ChildrenForm formKey="customer" />,
};

const ChildrenStepper = forwardRef<ChildrenStepperRef, ChildrenStepperProps>(
  ({ onStepperStart }, ref) => {
    const { t } = useTranslation();

    const [activeStep, setActiveStep] = useState(CustomerSteps.PersonalDetails);
    const [doneSteps] = useState<string[]>([]);

    const childrenSteps = useMemo(() => {
      return [
        {
          id: CustomerSteps.PersonalDetails,
          title: t('customer:newCustomer.steps.personalDetails'),
        },
      ];
    }, [t]);

    const prevStep = () => {
      const prevStepIndex = findIndex(childrenSteps, { id: activeStep }) - 1;

      if (prevStepIndex < 0) {
        onStepperStart();
      } else {
        setActiveStep(childrenSteps[prevStepIndex].id as CustomerSteps);
      }
    };

    useImperativeHandle(ref, () => ({
      prevStep,
    }));

    return (
      <Stepper
        active={activeStep}
        done={doneSteps}
        stepMap={Steps}
        steps={childrenSteps}
      />
    );
  },
);

export default ChildrenStepper;
