import { Tab, Tabs as MUITabs } from '@mui/material';
import { map } from 'lodash';
import { FC, SyntheticEvent } from 'react';

import style from './style';
import { SXStyleSheet } from '@/utils';
import { useTranslation } from 'react-i18next';

export interface TabItem {
  id: string;
  label: string;
}

export interface TabsProps {
  activeTab: string;
  items: TabItem[];
  withBorder?: boolean;
  onSelect?: (item: string) => void;
}

const Tabs: FC<TabsProps> = ({ activeTab, items, withBorder, onSelect }) => {
  const { t } = useTranslation();

  const handleChange = (_: SyntheticEvent, tab: string) => {
    onSelect?.(tab);
  };

  const renderTab = ({ label, id }: TabItem) => {
    return <Tab key={id} label={t(label)} value={id} sx={style.tabContainer} />;
  };

  return (
    <MUITabs
      sx={SXStyleSheet.merge(style.container, withBorder && style.border)}
      value={activeTab}
      onChange={handleChange}
    >
      {map(items, renderTab)}
    </MUITabs>
  );
};

export default Tabs;
