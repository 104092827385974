import { TableHeaderItem } from '@/components/ui/TableHeader';

// @todo(KAN-83): add translation keys to table header items

export const AdminTableColumns: TableHeaderItem[] = [
  {
    sortKey: 'name',
    sortIcon: 'Down',
    name: 'Name',
  },
  {
    sortKey: 'email',
    sortIcon: 'Down',
    name: 'Email address',
  },
  {
    name: 'Role',
  },
  {
    name: 'Clients',
  },
  {
    name: 'Action',
  },
];

export const CustomerTableColumns: TableHeaderItem[] = [
  {
    name: 'Full Name',
  },
  {
    name: 'Address',
  },
  {
    name: 'PLZ',
  },
  {
    name: 'Ort',
  },
  {
    name: 'Bruttogehalt',
  },
  {
    name: 'Nettogehalt',
  },
  {
    name: 'Haben Sie Wohneigen tum?',
  },
  {
    name: 'Sind Sie Raucher?',
  },
  {
    name: 'Größe',
  },
  {
    name: 'Gewicht',
  },
  {
    name: 'Höchster Bildungsabschluss',
  },
  {
    name: 'Höhe der Miete / Darlehen srate',
  },
];
