import { useCallback } from 'react';
import { findIndex } from 'lodash';

import useAuthStore from '@/store/auth';
import { Permissions } from '@/types';
import { hasPermission } from '@/utils/permissions';

export type VisibleItem<T> = [
  permisssions: Permissions[],
  visibleItem: T,
  not?: Permissions[],
];

const usePermissions = () => {
  const { user } = useAuthStore();

  const select = useCallback(
    <T>(components: VisibleItem<T>[]) => {
      if (!user) return null;

      const componentIndex = findIndex(components, (component) => {
        return hasPermission(component[0], user.permissions, component[2]);
      });

      return componentIndex === -1 ? null : components[componentIndex][1];
    },
    [user],
  );

  return {
    select,
  };
};

export default usePermissions;
