import network from '@/services/network';
import { PaginatedResponse, Customer, ApiResponse } from '@/types';
import { ErrorResponse } from '@/utils';
import { normalizeData } from '@/utils/system';

const getCustomers = async (count: number, page: number) => {
  try {
    const { data } = await network.get<PaginatedResponse<Customer>>(
      '/customers',
      {
        params: {
          page,
          per_page: count,
        },
      },
    );

    return data;
  } catch (error) {
    throw new ErrorResponse(error);
  }
};

const getCustomer = async (id: string) => {
  try {
    const {
      data: { data },
    } = await network.get<ApiResponse<Partial<Customer>>>(`/customers/${id}`);

    return data;
  } catch (error) {
    throw new ErrorResponse(error);
  }
};

const createCustomer = async (
  customer?: Partial<Customer>,
): Promise<Partial<Customer>> => {
  try {
    const {
      data: { data },
    } = await network.post<ApiResponse<Partial<Customer>>>(
      '/customers',
      customer,
    );
    return data;
  } catch (error) {
    throw new ErrorResponse(error);
  }
};

const updateCustomer = async (id: string, customer: Partial<Customer>) => {
  try {
    const {
      data: { data },
    } = await network.put<ApiResponse<Partial<Customer>>>(
      `/customers/${id}`,
      normalizeData(customer, ['id', 'user_id', 'updated_at', 'created_at']),
    );

    return data;
  } catch (error) {
    throw new ErrorResponse(error);
  }
};

const customer = {
  getCustomers,
  getCustomer,
  createCustomer,
  updateCustomer,
};

export default customer;
