import { SideBarItemData } from '@/components/ui/SideBarItem';
import { Routes } from '@/constants/routes';

export const SideBarMaxLetterLength = 22;

//@todo(KAN-40): connect with translations when all sidebar will be ready
export const SideBarAdminNavigation: SideBarItemData[] = [
  {
    name: 'Berater',
    icon: 'Home',
    path: Routes.Admin,
  },
  {
    name: 'Mandanten',
    icon: 'Section',
    path: Routes.Customers,
  },
  {
    name: 'Versicherungen',
    icon: 'Section',
    path: Routes.Root,
    items: [
      {
        name: 'Lebensversicherungen',
        path: Routes.Root,
      },
    ],
  },
];

export const SideBarConsultantNavigation: SideBarItemData[] = [
  {
    name: 'Mandanten',
    icon: 'Section',
    path: Routes.Customers,
  },
  {
    name: 'Versicherungen',
    icon: 'Section',
    path: Routes.Root,
    items: [
      {
        name: 'Lebensversicherungen',
        path: Routes.Root,
      },
    ],
  },
];

// @todo(KAN-92):connect with translations and paths
export const NewCustomerSideBar: SideBarItemData[] = [
  {
    name: 'Kundenprofil',
    icon: 'AccountCircle',
    path: Routes.NewCustomer,
  },
  {
    name: 'FörderSach',
    icon: 'Money',
    path: '/',
    items: [
      {
        name: 'Förderungen',
        path: '/',
      },
      {
        name: 'Sach',
        path: '/',
      },
    ],
  },
  {
    name: 'Finanzplaner',
    icon: 'Planner',
    path: '/',
    items: [
      {
        name: 'Leben Bestand',
        path: '/',
      },
      {
        name: 'Sparvergleich Einzeln',
        path: '/',
      },
      {
        name: 'Bausparvertrag',
        path: '/',
      },
      {
        name: 'bAV Rechner',
        path: '/',
      },
      {
        name: 'KLV',
        path: '/',
      },
    ],
  },
  {
    name: 'Lebensplanung +',
    icon: 'TodoList',
    path: '/',
  },
  {
    name: 'Konzept',
    icon: 'Idea',
    path: '/',
  },
  {
    name: 'Unterlagenliste',
    icon: 'Wallet',
    path: '/',
  },
  {
    name: 'Haushalt & Analyse',
    icon: 'Chart',
    path: '/',
  },
];
