import { create } from 'zustand';
import { CustomerState, CustomerStore } from '@/store/customer/types';
import { ErrorResponse } from '@/utils';
import api from '@/services/api';

const initialState: CustomerState = {
  customer: null,
  partner: null,
  loading: false,
  error: null,
};

const useCustomerStore = create<CustomerStore>((set, state) => ({
  ...initialState,
  createCustomer: async (customerKey, data) => {
    set({ loading: true });
    try {
      const customer = await api.customer.createCustomer(data);
      if (data?.partner_id && customerKey === 'partner') {
        state().fetchCustomer('customer', data.partner_id);
      }
      set({ [customerKey]: customer });
    } catch (e) {
      set({ error: (e as ErrorResponse).getType() });
    } finally {
      set({ loading: false });
    }
  },
  fetchCustomer: async (customerKey, id) => {
    set({ loading: true });
    try {
      const customer = await api.customer.getCustomer(id.toString());
      set({ [customerKey]: customer });
    } catch (e) {
      set({ error: (e as ErrorResponse).getType() });
    } finally {
      set({ loading: false });
    }
  },
  updateCustomer: async (customerKey, id, data) => {
    set({ loading: true });
    try {
      const customer = await api.customer.updateCustomer(id.toString(), data);
      set({ [customerKey]: customer });
    } catch (e) {
      set({ error: (e as ErrorResponse).getType() });
    } finally {
      set({ loading: false });
    }
  },
  clearState: () => {
    set(initialState);
  },
}));

export default useCustomerStore;
