import { Stack, Typography } from '@mui/material';
import { FC } from 'react';

import style from './style';

export interface UserProps {
  name: string;
}

const User: FC<UserProps> = ({ name }) => {
  return (
    <Stack sx={style.container}>
      <Stack sx={style.userCircle}>
        <Typography sx={style.userLetter}>{name.charAt(0)}</Typography>
      </Stack>
      <Typography variant="body2" fontWeight={500} color="text.secondary">
        {name}
      </Typography>
    </Stack>
  );
};

export default User;
