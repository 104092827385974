import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';

import SelectField from '@/components/formControl/SelectField';
import useTranslatedEnum from '@/hooks/useTranslatedEnum';
import TextField from '@/components/formControl/TextField';

import {
  CustomerForm,
  CustomerFormProps,
  EmploymentStatus,
  HealthInsuranceStatus,
} from '@/types';
import { getFormKey, showIf } from '@/utils/system';
import { useFormikContext } from 'formik';
import { FC } from 'react';
import { customerVisibilityKeys } from '@/constants/form';

const HealthInsuranceForm: FC<CustomerFormProps> = ({ formKey }) => {
  const { values } = useFormikContext<CustomerForm>();
  const { t } = useTranslation();

  const [healthInsuranceStatus] = useTranslatedEnum(
    'HealthInsuranceStatus',
    HealthInsuranceStatus,
  );

  return (
    <Stack gap={6} pb={10}>
      {showIf(
        values[formKey],
        'employment_status',
        customerVisibilityKeys.healthInsuranceStatus,
        <SelectField
          fieldName={getFormKey(formKey, 'health_insurance_status')}
          items={healthInsuranceStatus}
          label={t('customer:newCustomer.form.healthInsuranceStatus.label')}
        />,
      )}
      {showIf(
        values[formKey],
        'employment_status',
        [
          ...customerVisibilityKeys.healthInsuranceStatus,
          EmploymentStatus.CivilServant,
        ],
        <TextField
          inline
          hideErrorMessage
          fieldName={getFormKey(formKey, 'health_insurance_provider')}
          label={t('customer:newCustomer.form.healthInsuranceProvider.label')}
          placeholder={t(
            'customer:newCustomer.form.healthInsuranceProvider.placeholder',
          )}
        />,
      )}
    </Stack>
  );
};

export default HealthInsuranceForm;
