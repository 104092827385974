import { Stack, Typography } from '@mui/material';
import { FC } from 'react';

import style from './style';

export interface BadgeProps {
  text: string;
}

const Badge: FC<BadgeProps> = ({ text }) => {
  return (
    <Stack sx={style.container}>
      <Typography sx={style.badgeText}>{text}</Typography>
    </Stack>
  );
};

export default Badge;
