import { SXStyleSheet } from '@/utils';

const style = SXStyleSheet.create({
  label: {
    fontWeight: 500,
  },
  container: {
    flex: 1,
    flexDirection: 'row',
  },
  picker: {},
});

export default style;
