import { TableCell, TableHead, TableRow } from '@mui/material';
import { FC } from 'react';
import { map, truncate } from 'lodash';

import { IconType } from '@/assets/icons';
import style from './style';

export interface TableHeaderItem {
  name: string;
  sortKey?: string;
  sortIcon?: IconType;
}

export interface TableHeaderProps {
  items: TableHeaderItem[];
}

const TableHeader: FC<TableHeaderProps> = ({ items }) => {
  const renderTableCell = (item: TableHeaderItem, i: number) => {
    return (
      <TableCell sx={{ minWidth: '200px' }} key={i}>
        {truncate(item.name, { length: 20 })}
      </TableCell>
    );
  };

  return (
    <TableHead sx={style.container}>
      <TableRow>{map(items, renderTableCell)}</TableRow>
    </TableHead>
  );
};

export default TableHeader;
