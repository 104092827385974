import useCustomersStore from '@/store/customers';
import useCustomerStore from '@/store/customer';
import useUsersStore from '@/store/users';

const useClearState = () => {
  const { clearState: clearCustomersState } = useCustomersStore();
  const { clearState: clearCustomerState } = useCustomerStore();
  const { clearState: clearUsersState } = useUsersStore();

  const clearAllStates = () => {
    clearCustomersState();
    clearCustomerState();
    clearUsersState();
  };

  return {
    clearAllStates,
    clearCustomersState,
    clearUsersState,
    clearCustomerState,
  };
};

export default useClearState;
