import Network from '@/services/network';
import {CreateUserRequest, CreateUserResponse, PaginatedResponse, User} from '@/types';
import { ErrorResponse } from '@/utils';
import network from "@/services/network";

const getUsers = async (userCount: number, page: number) => {
  try {
    const { data } = await Network.get<PaginatedResponse<User>>('/users', {
      params: {
        page,
        per_page: userCount,
      },
    });
    return data;
  } catch (error) {
    throw new ErrorResponse(error);
  }
};

const createUser = async (user: CreateUserRequest): Promise<CreateUserResponse> => {
  try {
    const { data } = await network.post<CreateUserResponse>(
        '/users',
        user,
    );

    return data;
  } catch (error) {
    throw new ErrorResponse(error);
  }
};

const user = {
  getUsers,
  createUser
};

export default user;
