import { SXStyleSheet } from '@/utils';
import { buttonClasses } from '@mui/material';

const style = SXStyleSheet.create({
  container: {
    gap: 2,
    py: 0,
    px: 1,
    display: 'flex',
    flexDirection: 'row',
    [`&.${buttonClasses.colorInherit}`]: {
      color: 'text.secondary',
    },
  },
  icon: {
    minWidth: 32,
  },
});

export default style;
