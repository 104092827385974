import { Stack, Typography } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';

import LinkButton from '@/components/ui/LinkButton';
import { CompanyRoute } from '@/constants/routes';

const CopyrightNotice = () => {
  const { t } = useTranslation();

  return (
    <Stack alignItems="center" justifySelf="end" pb="25px">
      <Typography>{t('common:copyright')}</Typography>
      <Typography
        variant="body2"
        display="flex"
        alignItems="center"
        flexDirection="row"
        gap={1}
      >
        <Trans
          i18nKey="common:poweredBy"
          components={{
            link: (
              <LinkButton text={t('common:brandName')} href={CompanyRoute} />
            ),
          }}
        />
      </Typography>
    </Stack>
  );
};

export default CopyrightNotice;
