import { Stack, Checkbox, Typography } from '@mui/material';
import { ChangeEvent, FC, useMemo } from 'react';
import { indexOf } from 'lodash';

import style from './style';

export type CheckOptions = [caseFalse: string, caseTrue: string];

export interface CheckBoxProps {
  value: string;
  label: string;
  options: CheckOptions;
  onChange?: (value: string) => void;
}

const CheckBox: FC<CheckBoxProps> = ({ value, options, label, onChange }) => {
  const isChecked = useMemo(() => {
    return indexOf(options, value) > 0;
  }, [options, value]);

  const handleChange = (
    _: ChangeEvent<HTMLInputElement>,
    nextValue: boolean,
  ) => {
    onChange?.(options[nextValue ? 1 : 0]);
  };

  return (
    <Stack style={style.container}>
      <Checkbox checked={isChecked} onChange={handleChange} />
      <Typography variant="body2" fontWeight={500}>
        {label}
      </Typography>
    </Stack>
  );
};

export default CheckBox;
