import { SXStyleSheet } from '@/utils';

const style = SXStyleSheet.create({
  label: {
    fontWeight: 500,
  },
  container: {
    flex: 1,
  },
  error: {
    margin: 0,
    fontSize: '14px',
  },
  icon: {
    mr: 2,
  },
});

export default style;
