import common from './common.json';
import auth from './auth.json';
import errors from './errors.json';
import admin from './admin.json';
import customer from './customer.json';
import enums from './enums.json';

const deDE = {
  common,
  auth,
  errors,
  admin,
  enums,
  customer,
};

export default deDE;
