import { Stack } from '@mui/material';
import Step, { StepData } from '@/components/ui/Step';
import { FC } from 'react';
import { map } from 'lodash';

import style from './style';

export interface StepperHeaderProps {
  active: string;
  done: string[];
  steps: StepData[];
  onStepSelect?: (id: string) => void;
}

const StepperHeader: FC<StepperHeaderProps> = ({
  active,
  done,
  steps,
  onStepSelect,
}) => {
  const renderStep = (step: StepData) => {
    return (
      <Step
        onClick={onStepSelect}
        done={done}
        active={active}
        {...step}
        key={step.id}
      />
    );
  };

  return <Stack sx={style.container}>{map(steps, renderStep)}</Stack>;
};

export default StepperHeader;
