import { Box, BoxProps } from '@mui/material';
import Icons, { IconType } from '@/assets/icons';
import { FC } from 'react';

export interface IconProps {
  icon: IconType;
  size?: number;
  sx?: BoxProps['sx'];
}

const Icon: FC<IconProps> = ({ icon, size, sx }) => {
  const IconComponent = Icons[icon];

  return <Box sx={sx} component={IconComponent} width={size} height={size} />;
};

export default Icon;
