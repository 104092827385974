import { ButtonBase, Collapse, Stack, Typography } from '@mui/material';
import { FC, useCallback, useState } from 'react';

import Icon from '@/components/ui/Icon';
import { IconType } from '@/assets/icons';

import style from './style';
import IconButton from '@/components/ui/IconButton';
import { map } from 'lodash';
import { SXStyleSheet } from '@/utils';
import { Routes } from '@/constants/routes';

export interface SideBarItemData {
  name: string;
  path: string;
  icon?: IconType;
  items?: SideBarItemData[];
}

export interface SideBarItemProps {
  item: SideBarItemData;
  active?: boolean;
  altColor?: boolean;
  onSideBarItemClick?: (item: SideBarItemData) => void;
}

const SideBarItem: FC<SideBarItemProps> = ({
  item,
  active,
  altColor,
  onSideBarItemClick,
}) => {
  const [opened, setOpened] = useState(false);

  const handleClick = useCallback(
    (subItem?: SideBarItemData) => () => {
      // @todo(KAN-140): this behaviour is for mock items, will be removed when all items in sidebar will be available
      if (subItem?.path === Routes.Root || item.path === Routes.Root) {
        return;
      }

      onSideBarItemClick?.(subItem || item);
    },
    [item, onSideBarItemClick],
  );

  const renderSubitem = (item: SideBarItemData, i: number) => {
    return (
      <Stack key={i} sx={style.subItemContainer}>
        <ButtonBase
          sx={style.subItemButtonContainer}
          onClick={handleClick(item)}
        >
          <Typography>{item.name}</Typography>
        </ButtonBase>
      </Stack>
    );
  };

  const onDropDownClick = () => {
    setOpened((prevState) => !prevState);
  };

  return (
    <Stack>
      <Stack
        sx={SXStyleSheet.merge(
          style.container,
          active && style.containerActive,
          altColor && active && style.altColorActive,
        )}
      >
        <ButtonBase
          sx={SXStyleSheet.merge(
            style.buttonContainer,
            altColor && active && style.buttonContainerAltColor,
          )}
          disableRipple={true}
          onClick={handleClick()}
        >
          {!!item.icon && <Icon icon={item.icon} />}
          <Typography variant="body1" fontWeight={600}>
            {item.name}
          </Typography>
        </ButtonBase>
        {!!item.items && !!item.items.length && (
          <IconButton
            sx={SXStyleSheet.merge(opened && style.iconActive)}
            icon="Down"
            onClick={onDropDownClick}
          />
        )}
      </Stack>
      <Collapse in={opened}>{map(item.items, renderSubitem)}</Collapse>
    </Stack>
  );
};

export default SideBarItem;
