import { useTranslation } from 'react-i18next';
import { SingleSelectItem } from '@/components/ui/SingleSelect';
import { getTranslationKey } from '@/utils/system';

const useTranslatedEnum = <T extends object>(
  enumKey: string,
  enumValues: T,
) => {
  const { t } = useTranslation();

  const data: SingleSelectItem[] = Object.values(enumValues).map((key) => ({
    label: t(getTranslationKey(`enums:${enumKey}.${key}`, key)),
    value: key,
  }));

  return [data, enumValues] as [SingleSelectItem[], T];
};

export default useTranslatedEnum;
