import axios from 'axios';
import env from '@/constants/env';

const Network = axios.create({
  baseURL: env.baseUrl,
});

export const initializeAuthSession = (token?: string) => {
  clearInterceptors();
  Network.interceptors.request.use(
    (config) => {
      config.headers.Authorization = token ? `Bearer ${token}` : undefined;
      return config;
    },
    (error) => Promise.reject(error),
  );
};

export const clearInterceptors = () => {
  Network.interceptors.request.clear();
};

export default Network;
