import { MutableRefObject, useEffect, useMemo, useRef, useState } from 'react';
import { Stack } from '@mui/material';
import { findIndex } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Formik, FormikProps } from 'formik';

import TabsView from '@/components/ui/TabsView';
import SideBar from '@/components/ui/SideBar';
import Tabs from '@/components/ui/Tabs';
import NewCustomerHeader from '@/components/NewCustomerHeader';
import NewCustomerStepper, {
  NewCustomerStepperRef,
} from '@/components/NewCustomerStepper';
import ChildrenStepper from '@/components/ChildrenStepper';

import { NewCustomerSideBar } from '@/constants/sidebar';
import { NewCustomerTab, NewCustomerTabItems } from '@/constants/tabs';
import { CustomerForm } from '@/types';
import { useNavigate, useSearchParams } from 'react-router-dom';
import useCustomerStore from '@/store/customer';
import { getPath } from '@/utils';
import { Routes } from '@/constants/routes';
import { updateCustomerValidationSchema } from '@/constants/validation';

const NewCustomerView = () => {
  const formRef = useRef<FormikProps<CustomerForm>>(null);

  const [searchParams] = useSearchParams();

  const customerId = searchParams.get('id');
  const { customer, partner, updateCustomer, fetchCustomer, createCustomer } =
    useCustomerStore();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const stepperRef = useRef<
    Record<NewCustomerTab, MutableRefObject<NewCustomerStepperRef | null>>
  >({
    client: { current: null },
    partner: { current: null },
    children: { current: null },
  });

  const [activeTab, setActiveTab] = useState(NewCustomerTab.Client);
  const [prevStepDisabled, setPrevStepDisabled] = useState(true);

  useEffect(() => {
    if (!customerId) {
      return;
    }

    fetchCustomer('customer', customerId).catch(console.error);
  }, [customerId, fetchCustomer]);

  useEffect(() => {
    if (
      activeTab === NewCustomerTab.Partner &&
      !customer?.partner?.id &&
      customerId
    ) {
      createCustomer('partner', { partner_id: parseInt(customerId) }).catch(
        console.error,
      );
    }

    if (customer?.partner?.id) {
      fetchCustomer('partner', customer.partner.id).catch(console.error);
    }
  }, [
    activeTab,
    createCustomer,
    customer?.partner?.id,
    customerId,
    fetchCustomer,
  ]);

  useEffect(() => {
    if (customer || partner) {
      formRef.current?.setValues({
        customer,
        partner,
      });
    }
  }, [customer, partner]);

  const activeTabIndex = useMemo(() => {
    return findIndex(NewCustomerTabItems, { id: activeTab });
  }, [activeTab]);

  const handleSelectActiveTab = async (tab: string) => {
    if (prevStepDisabled) {
      setPrevStepDisabled(false);
    }

    await formRef.current?.submitForm();
    setActiveTab(tab as NewCustomerTab);
  };

  const handleNext = async () => {
    await formRef.current?.submitForm();

    if (prevStepDisabled) {
      setPrevStepDisabled(false);
    }

    stepperRef.current[activeTab].current?.nextStep();
  };

  const handlePrev = async () => {
    await formRef.current?.submitForm();
    stepperRef.current[activeTab].current?.prevStep();
  };

  const handleSave = async () => {
    await formRef.current?.submitForm();
    navigate(getPath('..', '..', Routes.Home));
  };

  const handleStepperEnd = () => {
    const nextTabIndex = activeTabIndex + 1;

    if (nextTabIndex < NewCustomerTabItems.length) {
      setActiveTab(NewCustomerTabItems[nextTabIndex].id as NewCustomerTab);
    }
  };

  const handleStepperStart = () => {
    const nextTabIndex = activeTabIndex - 1;

    if (nextTabIndex >= 0) {
      setActiveTab(NewCustomerTabItems[nextTabIndex].id as NewCustomerTab);
    }
  };

  const handleFirstStep = () => {
    setPrevStepDisabled(true);
  };

  const handleSubmit = async (data: CustomerForm) => {
    const updateKey =
      activeTab === NewCustomerTab.Partner ? 'partner' : 'customer';

    const updateId =
      activeTab === NewCustomerTab.Partner ? customer?.partner?.id : customerId;

    const updateData = data[updateKey];

    if (!updateId || !updateData) {
      return;
    }

    updateCustomer(updateKey, updateId, updateData).catch(console.error);
  };

  const handleExitFromCustomer = () => {
    navigate(getPath('..', Routes.Home, Routes.Customers));
  };

  return (
    <Formik
      innerRef={formRef}
      initialValues={{
        customer,
        partner,
      }}
      validationSchema={updateCustomerValidationSchema}
      onSubmit={handleSubmit}
    >
      <Stack flex={1} direction="row" overflow="hidden">
        {/* @todo(KAN-92): update the side bar position to root?*/}
        <SideBar
          items={NewCustomerSideBar}
          backLabel={t('customer:newCustomer.goBack')}
          onGoBack={handleExitFromCustomer}
          altColor
        />
        <Stack flex={1} overflow="hidden">
          <NewCustomerHeader
            title={t('customer:newCustomer.title')}
            subtitle={t('customer:newCustomer.subtitle')}
            nextLabel={t('customer:newCustomer.next')}
            saveLabel={t('customer:newCustomer.save')}
            onNext={handleNext}
            onBack={handlePrev}
            onSave={handleSave}
            prevDisabled={prevStepDisabled}
            finalStep={activeTab === NewCustomerTab.Children}
          />
          <Stack flex={1} px={6} gap={6} overflow="hidden">
            <Tabs
              withBorder
              activeTab={activeTab}
              items={NewCustomerTabItems}
              onSelect={handleSelectActiveTab}
            />
            <TabsView
              activeTabIndex={activeTabIndex}
              tabs={[
                <NewCustomerStepper
                  ref={stepperRef.current.client}
                  onFirstStep={handleFirstStep}
                  onStepperEnd={handleStepperEnd}
                  onStepperStart={handleStepperStart}
                  formType="customer"
                />,
                <NewCustomerStepper
                  ref={stepperRef.current.partner}
                  onStepperEnd={handleStepperEnd}
                  onStepperStart={handleStepperStart}
                  formType="partner"
                />,
                <ChildrenStepper
                  ref={stepperRef.current.children}
                  onStepperStart={handleStepperStart}
                />,
              ]}
            />
          </Stack>
        </Stack>
      </Stack>
    </Formik>
  );
};

export default NewCustomerView;
