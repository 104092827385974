import { create } from 'zustand';
import { persist } from 'zustand/middleware';

import { AuthState, AuthStore } from './types';
import api from '@/services/api';
import { clearInterceptors, initializeAuthSession } from '@/services/network';
import { ErrorResponse } from '@/utils';

const initialState: AuthState = {
  user: null,
  loading: false,
  error: null,
};

const useAuthStore = create(
  persist<AuthStore>(
    (set, getState, storeApi) => ({
      ...initialState,
      signIn: async (credentials) => {
        set({ loading: true, error: null });
        try {
          const user = await api.auth.signIn(credentials);
          set({ user });
        } catch (error) {
          set({ error: (error as ErrorResponse).getType() });
        } finally {
          set({ loading: false });
        }
      },
      signOut: () => {
        set(initialState);
        storeApi.persist.clearStorage();
        clearInterceptors();
      },
      clearError: () => {
        set({ error: null });
      },
    }),
    {
      name: 'auth-storage',
      partialize: (state) => ({ user: state.user }) as AuthStore,
      onRehydrateStorage: () => (state) => {
        if (state?.user && state.user.accessToken) {
          initializeAuthSession(state.user.accessToken);
        }
      },
    },
  ),
);

export default useAuthStore;
