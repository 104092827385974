import React, { FC } from 'react';
import { Stack } from '@mui/material';

import style from './style';
import { map } from 'lodash';
import { SXStyleSheet } from '@/utils';

export interface TabsViewProps {
  activeTabIndex: number;
  tabs: JSX.Element[];
}

const TabsView: FC<TabsViewProps> = ({ activeTabIndex, tabs }) => {
  const renderTabItem = (tabItem: JSX.Element, i: number) => {
    return (
      <Stack
        sx={SXStyleSheet.merge(
          style.stepContainer,
          activeTabIndex === i && { display: 'flex' },
        )}
        key={i}
      >
        {tabItem}
      </Stack>
    );
  };

  return <Stack sx={style.container}>{map(tabs, renderTabItem)}</Stack>;
};

export default TabsView;
