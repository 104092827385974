import { SXStyleSheet } from '@/utils';

const style = SXStyleSheet.create({
  container: {
    pl: 3,
    pt: '41px',
    pb: 6,
    flexDirection: 'row',
    alignItems: 'center',
    borderTop: '1px solid',
    borderTopColor: 'divider',
  },
  userContainer: {
    gap: '12px',
    alignItems: 'center',
    flexDirection: 'row',
    flex: 1,
  },
  avatar: {
    width: '40px',
    height: '40px',
    borderRadius: '20px',
    backgroundColor: '#D33F33',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
  },
  avatarLetter: {
    fontSize: '24px',
    fontWeight: 400,
    color: 'secondary.contrastText',
  },
});

export default style;
