import { Stack, StackProps } from '@mui/material';
import { FC, PropsWithChildren } from 'react';

import style from './style';

const ScreenWrapper: FC<PropsWithChildren<StackProps>> = ({
  children,
  ...props
}) => {
  return (
    <Stack sx={style.container} {...props}>
      {children}
    </Stack>
  );
};

export default ScreenWrapper;
