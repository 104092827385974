export enum ErrorType {
  UnknownError = 'UnknownError',
  NotFound = 'NotFound',
  Unauthorized = 'Unauthorized',
  Forbidden = 'Forbidden',
  BadRequest = 'BadRequest',
  Conflict = 'Conflict',
  InternalServerError = 'InternalServerError',
}

export type ErrorMessageResponse = {
  message?: string;
};
