import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FC } from 'react';

import TextField from '@/components/formControl/TextField';
import DateField from '@/components/formControl/DateField';
import SelectField from '@/components/formControl/SelectField';
import useTranslatedEnum from '@/hooks/useTranslatedEnum';
import RadioSelectField from '@/components/formControl/RadioSelectField';

import { CustomerFormProps, MaterialStatus, YesNo } from '@/types';
import { getFormKey } from '@/utils/system';
import moment from 'moment';

const PersonalDetailsForm: FC<CustomerFormProps> = ({ formKey }) => {
  const { t } = useTranslation();

  const [materialStatus] = useTranslatedEnum('MaterialStatus', MaterialStatus);
  const [yseNo] = useTranslatedEnum('YesNo', YesNo);

  const radioValueMapper = (
    value: string | number | boolean,
  ): string | undefined => {
    if (typeof value !== 'boolean') {
      return;
    }

    return value ? YesNo.Yes : YesNo.No;
  };

  const radioValueSelectMapper = (value: string) => {
    return value === YesNo.Yes;
  };

  return (
    <Stack gap={6} pb={10}>
      <TextField
        inline
        hideErrorMessage
        fieldName={getFormKey(formKey, 'first_name')}
        label={t('customer:newCustomer.form.firstName.label')}
        placeholder={t('customer:newCustomer.form.firstName.placeholder')}
      />
      <TextField
        inline
        hideErrorMessage
        fieldName={getFormKey(formKey, 'last_name')}
        label={t('customer:newCustomer.form.lastName.label')}
        placeholder={t('customer:newCustomer.form.lastName.placeholder')}
      />
      <DateField
        fieldName={getFormKey(formKey, 'date_of_birth')}
        label={t('customer:newCustomer.form.dateOfBirth.label')}
        max={moment()}
      />
      <SelectField
        fieldName={getFormKey(formKey, 'marital_status')}
        items={materialStatus}
        label={t('customer:newCustomer.form.materialStatus.label')}
      />
      <TextField
        inline
        hideErrorMessage
        fieldName={getFormKey(formKey, 'address')}
        label={t('customer:newCustomer.form.address.label')}
        placeholder={t('customer:newCustomer.form.address.placeholder')}
      />
      <TextField
        inline
        hideErrorMessage
        fieldName={getFormKey(formKey, 'postal_code')}
        label={t('customer:newCustomer.form.postalCode.label')}
        placeholder={t('customer:newCustomer.form.postalCode.placeholder')}
      />
      <TextField
        inline
        hideErrorMessage
        fieldName={getFormKey(formKey, 'city')}
        label={t('customer:newCustomer.form.city.label')}
        placeholder={t('customer:newCustomer.form.city.placeholder')}
      />
      <TextField
        inline
        hideErrorMessage
        fieldName={getFormKey(formKey, 'weight')}
        label={t('customer:newCustomer.form.weight.label')}
        placeholder={t('customer:newCustomer.form.weight.placeholder')}
      />
      <TextField
        inline
        hideErrorMessage
        fieldName={getFormKey(formKey, 'height')}
        label={t('customer:newCustomer.form.height.label')}
        placeholder={t('customer:newCustomer.form.height.placeholder')}
      />
      <RadioSelectField
        options={yseNo}
        fieldName={getFormKey(formKey, 'is_smoker')}
        valueTransformer={radioValueMapper}
        selectTransformer={radioValueSelectMapper}
        label={t('customer:newCustomer.form.isSmoker.label')}
      />
    </Stack>
  );
};

export default PersonalDetailsForm;
