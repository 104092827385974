import { SXStyleSheet } from '@/utils';

const style = SXStyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 2.5,
  },
  userCircle: {
    width: '40px',
    height: '40px',
    borderRadius: '20px',
    backgroundColor: '#407887',
    alignItems: 'center',
    justifyContent: 'center',
  },
  userLetter: {
    fontSize: '24px',
    fontWeight: 400,
    color: 'primary.contrastText',
  },
});

export default style;
