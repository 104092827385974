import { Stack } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import TextField from '@/components/formControl/TextField';
import DateField from '@/components/formControl/DateField';
import TextButton from '@/components/ui/TextButton';

import { Child } from '@/types';
import moment from 'moment';

export const childInitialValues: Child = {
  name: '',
  year_of_birth: '',
};

export interface ChildFormProps {
  formKey: string;
  index: number;
  onDelete: (index: number) => void;
}

const ChildForm: FC<ChildFormProps> = ({ index, formKey, onDelete }) => {
  const { t } = useTranslation();

  const handleDelete = () => {
    onDelete(index);
  };

  return (
    <Stack gap={8}>
      <Stack flexDirection="row" alignItems="center" gap={8} px={6}>
        <TextField
          inline
          hideErrorMessage
          labelFixedWidth="150px"
          fieldName={`${formKey}.${index}.name`}
          label={t('customer:newCustomer.form.firstName.label')}
          placeholder={t('customer:newCustomer.form.firstName.placeholder')}
        />
        <DateField
          labelFixedWidth="150px"
          fieldName={`${formKey}.${index}.year_of_birth`}
          label={t('customer:newCustomer.form.dateOfBirth.label')}
          format="YYYY"
          views={['year']}
          max={moment()}
        />
        <TextButton
          icon="Delete"
          text={t('customer:newCustomer.delete')}
          color="error"
          onClick={handleDelete}
        />
      </Stack>
    </Stack>
  );
};

export default ChildForm;
