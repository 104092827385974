import { Stack } from '@mui/material';
import { FC, JSX } from 'react';
import StepperHeader, {
  StepperHeaderProps,
} from '@/components/ui/StepperHeader';

export type StepMap = Record<string, JSX.Element>;

export interface StepperProps extends StepperHeaderProps {
  stepMap: StepMap;
  onStepSelect?: (id: string) => void;
}

const Stepper: FC<StepperProps> = ({
  steps,
  active,
  done,
  stepMap,
  onStepSelect,
}) => {
  return (
    <Stack flex={1} overflow="hidden">
      <StepperHeader
        active={active}
        done={done}
        steps={steps}
        onStepSelect={onStepSelect}
      />
      <Stack flex={1} overflow="auto">
        {stepMap[active]}
      </Stack>
    </Stack>
  );
};

export default Stepper;
