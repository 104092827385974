import { create } from 'zustand';

import { CustomerStore, CustomerState } from './types';
import api from '@/services/api';
import { ErrorResponse } from '@/utils';

const initialState: CustomerState = {
  customers: null,
  pagination: null,
  loading: false,
  error: null,
};

const useCustomersStore = create<CustomerStore>((set, getState) => ({
  ...initialState,
  fetchCustomers: async (count: number) => {
    set({ loading: true });
    try {
      const { data, meta } = await api.customer.getCustomers(count, 1);
      set({ customers: data, pagination: meta });
    } catch (error) {
      set({ error: (error as ErrorResponse).getType() });
    } finally {
      set({ loading: false });
    }
  },
  nextPage: async () => {
    const state = getState();
    if (!state.customers || !state.pagination) return;
    if (state.pagination?.current_page === state.pagination?.last_page) return;

    set({ loading: true });
    try {
      const { data, meta } = await api.customer.getCustomers(
        state.pagination.per_page,
        state.pagination.current_page + 1,
      );
      set({ customers: data, pagination: meta });
    } catch (error) {
      set({ error: (error as ErrorResponse).getType() });
    } finally {
      set({ loading: false });
    }
  },
  prevPage: async () => {
    const state = getState();
    if (!state.customers || !state.pagination) return;
    if (state.pagination?.current_page === 1) return;

    set({ loading: true });
    try {
      const { data, meta } = await api.customer.getCustomers(
        state.pagination.per_page,
        state.pagination.current_page - 1,
      );
      set({ customers: data, pagination: meta });
    } catch (error) {
      set({ error: (error as ErrorResponse).getType() });
    } finally {
      set({ loading: false });
    }
  },
  clearState: () => {
    set(initialState);
  },
}));

export default useCustomersStore;
