import { FC } from 'react';
import { Navigate, NavigateProps } from 'react-router-dom';
import usePermissions, { VisibleItem } from '@/hooks/usePermissions';
import { Routes } from '@/constants/routes';

export interface PermissionAwareNavigateProps
  extends Omit<NavigateProps, 'to'> {
  to: VisibleItem<string>[];
}

const PermissionAwareNavigate: FC<PermissionAwareNavigateProps> = ({ to }) => {
  const { select } = usePermissions();

  return <Navigate to={select(to) || Routes.Root} />;
};

export default PermissionAwareNavigate;
