import { SXStyleSheet } from '@/utils';

const style = SXStyleSheet.create({
  container: {
    width: '100%',
    flexDirection: 'row',
  },
  label: {
    fontWeight: 500,
  },
  radioGroup: {
    flexDirection: 'row',
    gap: 8,
  },
});

export default style;
