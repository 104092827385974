import { SXStyleSheet } from '@/utils';

const style = SXStyleSheet.create({
  container: {
    height: '22px',
    alignItems: 'center',
    justifyContent: 'center',
    px: 2,
    borderRadius: '11px',
    backgroundColor: '#F9F5FF',
  },
  badgeText: {
    fontSize: '12px',
    fontWeight: 500,
    color: 'text.secondary',
  },
});

export default style;
