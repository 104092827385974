import {
  BBesGHigherService,
  BBesGMiddleService,
  BBesGSeniorCivilService,
  BBesGSimpleService,
  EmploymentStatus,
} from '@/types';
import { getSelectItemsFromEnum } from '@/utils/system';

export const customerVisibilityKeys = {
  grossNetSalary: [EmploymentStatus.Contributions, EmploymentStatus.MiniJob],
  salaryCivilServant: [EmploymentStatus.CivilServant],
  income: [
    EmploymentStatus.Contributions,
    EmploymentStatus.SelfEmpWithEmp,
    EmploymentStatus.EmpMdPartner,
    EmploymentStatus.SelfEmpNoEmp,
    EmploymentStatus.MiniJob,
    EmploymentStatus.CivilServant,
  ],
  healthInsuranceStatus: [
    EmploymentStatus.Contributions,
    EmploymentStatus.SelfEmpWithEmp,
    EmploymentStatus.EmpMdPartner,
    EmploymentStatus.SelfEmpNoEmp,
    EmploymentStatus.MiniJob,
  ],
  jobTitle: [EmploymentStatus.Contributions, EmploymentStatus.MiniJob],
  jobTitleBBesG: [EmploymentStatus.CivilServant],
  legalForm: [
    EmploymentStatus.SelfEmpNoEmp,
    EmploymentStatus.SelfEmpWithEmp,
    EmploymentStatus.EmpMdPartner,
  ],
  employeeCount: [
    EmploymentStatus.SelfEmpWithEmp,
    EmploymentStatus.EmpMdPartner,
  ],
  revenueAndProfit: [
    EmploymentStatus.SelfEmpNoEmp,
    EmploymentStatus.SelfEmpWithEmp,
    EmploymentStatus.EmpMdPartner,
  ],
};

export const BBesGGroups = {
  BBesGSimpleService: getSelectItemsFromEnum(BBesGSimpleService),
  BBesGMiddleService: getSelectItemsFromEnum(BBesGMiddleService),
  BBesGSeniorCivilService: getSelectItemsFromEnum(BBesGSeniorCivilService),
  BBesGHigherService: getSelectItemsFromEnum(BBesGHigherService),
};
