import { Box, Stack, Typography } from '@mui/material';
import { FC } from 'react';

import IconButton from '@/components/ui/IconButton';
import { IconType } from '@/assets/icons';

import style from './style';
import { truncate } from 'lodash';
import { SideBarMaxLetterLength } from '@/constants/sidebar';

export interface UserCardProps {
  name: string;
  email: string;
  actionIcon: IconType;
  onAction: () => void;
}

const UserCard: FC<UserCardProps> = ({ name, email, onAction, actionIcon }) => {
  return (
    <Stack sx={style.container}>
      <Stack style={style.userContainer}>
        <Box sx={style.avatar}>
          <Typography sx={style.avatarLetter}>{name.charAt(0)}</Typography>
        </Box>
        <Stack>
          <Typography variant="body2" fontWeight={500}>
            {truncate(name, { length: SideBarMaxLetterLength })}
          </Typography>
          <Typography variant="body2">
            {truncate(email, { length: SideBarMaxLetterLength })}
          </Typography>
        </Stack>
      </Stack>
      <IconButton onClick={onAction} icon={actionIcon} />
    </Stack>
  );
};

export default UserCard;
