import { Permissions } from '@/types';

export const hasPermission = (
  checkPermission: Permissions[],
  permission: Permissions[],
  notPermissions?: Permissions[],
) => {
  return (
    checkPermission.every((p) => permission.includes(p)) &&
    !notPermissions?.some((p) => permission.includes(p))
  );
};
