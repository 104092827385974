import { Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useCallback, useEffect, useState } from 'react';

import Badge from '@/components/ui/Badge';
import Pagination from '@/components/ui/Pagination';
import NewUserDialog from '@/components/modals/NewUserDialog';
import AdminHeader from '@/components/AdminHeader';
import Table from '@/components/ui/Table';
import AdminTableRow from 'src/components/ui/UserTableRow';

import useAuthStore from '@/store/auth';
import useUsersStore from '@/store/users';
import { Customer, User } from '@/types';
import { NewUserFormValues } from '@/components/forms/NewUserForm';
import { getTranslationKey } from '@/utils/system';
import { AdminTableColumns } from '@/constants/table';

const AdminView = () => {
  const [newUserFlowModalOpened, setNewUserFlowModalOpened] =
    useState<boolean>(false);

  // @todo(KAN-119): update magic 7 with device screen height auto adjustments
  const [tableDataRows] = useState(7);

  const { t } = useTranslation();

  const { user } = useAuthStore();
  const {
    users,
    pagination,
    nextPage,
    prevPage,
    createError,
    fetchUsers,
    newUser,
    clearCreateErrors,
  } = useUsersStore();

  const getTableData = useCallback(async () => {
    fetchUsers(tableDataRows).catch(console.error);
  }, [fetchUsers, tableDataRows]);

  useEffect(() => {
    getTableData().catch();
  }, [getTableData]);

  const handleToggleModal = (state: boolean) => () => {
    if (!state) {
      clearCreateErrors();
    }

    setNewUserFlowModalOpened(state);
  };

  const handleSubmit = async (values: NewUserFormValues) => {
    try {
      await newUser(values);
      setNewUserFlowModalOpened(false);
    } catch (error) {
      console.error(error);
    }
  };

  const renderTableRow = (item: User | Customer) => {
    return <AdminTableRow data={item as User} key={item.id} />;
  };

  const renderTableTitle = useCallback(() => {
    return (
      <Stack>
        <Stack direction="row" alignItems="center" gap={2} px={6} pb={5}>
          <Typography variant="subtitle1">
            {t('admin:home.userTable.title')}
          </Typography>
          <Badge
            text={t('admin:home.userTable.userCount', {
              count: pagination?.total,
            })}
          />
        </Stack>
      </Stack>
    );
  }, [pagination?.total, t]);

  return (
    <Stack flex={1}>
      <AdminHeader
        username={user?.name || ''}
        onAddClick={handleToggleModal(true)}
      />
      <Stack flex={1} px={10} overflow="hidden">
        <Table
          data={users || []}
          renderRow={renderTableRow}
          renderTableTitle={renderTableTitle}
          headerItems={AdminTableColumns}
        />
        <Pagination
          page={pagination?.current_page || 0}
          total={pagination?.last_page || 0}
          nextPage={nextPage}
          prevPage={prevPage}
        />
      </Stack>
      <NewUserDialog
        open={newUserFlowModalOpened}
        onSubmit={handleSubmit}
        onClose={handleToggleModal(false)}
        error={
          !!createError &&
          t(
            getTranslationKey(
              `errors:response.${createError}`,
              `errors:network.BadRequest`,
            ),
          )
        }
      />
    </Stack>
  );
};

export default AdminView;
