import { Stack, Typography } from '@mui/material';

import Icon from '@/components/ui/Icon';

import style from './style';
import { FC, useMemo } from 'react';
import { truncate } from 'lodash';
import { SXStyleSheet } from '@/utils';

export interface StepData {
  id: string;
  title: string;
}

export interface StepProps extends StepData {
  active: string;
  done: string[];
  onClick?: (id: string) => void;
}

const Step: FC<StepProps> = ({ id, active, title, done, onClick }) => {
  const handleClick = () => {
    onClick?.(id);
  };

  const isActive = useMemo(() => {
    return id === active;
  }, [active, id]);

  const isDone = done.includes(id);

  return (
    <Stack sx={style.container} onClick={handleClick}>
      <Icon icon={isActive ? 'StepActive' : isDone ? 'StepSuccess' : 'Step'} />
      <Stack
        sx={SXStyleSheet.merge(
          style.titleContainer,
          done && style.titleContainerDone,
          isActive && style.titleContainerActive,
        )}
      >
        <Typography fontWeight="bold" noWrap>
          {truncate(title, { length: 18 })}
        </Typography>
        <Icon
          sx={SXStyleSheet.merge(
            style.nextStep,
            (isDone || isActive) && style.nextStepActive,
          )}
          icon="NextStep"
        />
      </Stack>
    </Stack>
  );
};

export default Step;
