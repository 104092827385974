import { Stack, Typography } from '@mui/material';
import CTAButton from '@/components/ui/CTAButton';
import { FC } from 'react';
import { IconType } from '@/assets/icons';
import { map } from 'lodash';
import IconButton from '@/components/ui/IconButton';
import style from './style';

export interface FilterProps {
  label: string;
  clearLabel: string;
  icon: IconType;
  // @todo(KAN-111): implement filters
  filterItems: string[];
}

const Filter: FC<FilterProps> = ({ label, icon, filterItems, clearLabel }) => {
  const renderFilterItem = (item: string, i: number) => {
    return (
      <Stack key={i} sx={style.filterItem}>
        <Typography
          variant="body2"
          fontWeight={500}
          color="text.secondary"
          noWrap
        >
          {item}
        </Typography>
        <IconButton icon="Close" />
      </Stack>
    );
  };

  return (
    <Stack sx={style.container}>
      <CTAButton size="small" icon={icon} label={label} variant="outlined" />
      {map(filterItems, renderFilterItem)}
      <Stack style={style.clearAll}>
        <IconButton icon="Close" />
        <Typography
          noWrap
          variant="body2"
          fontWeight={500}
          color="text.secondary"
        >
          {clearLabel}
        </Typography>
      </Stack>
    </Stack>
  );
};

export default Filter;
