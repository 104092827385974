import { FC } from 'react';
import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';

import SelectField from '@/components/formControl/SelectField';

import { ChildrenCount, CustomerFormProps } from '@/types';
import { getFormKey } from '@/utils/system';
import useTranslatedEnum from '@/hooks/useTranslatedEnum';

const ChildrenCountForm: FC<CustomerFormProps> = ({ formKey }) => {
  const { t } = useTranslation();

  const [childrenCount] = useTranslatedEnum('ChildrenCount', ChildrenCount);

  return (
    <Stack gap={6} pb={10}>
      <SelectField
        items={childrenCount}
        fieldName={getFormKey(formKey, 'child_benefit_eligible_children')}
        label={t(
          'customer:newCustomer.form.childBenefitEligibleChildren.label',
        )}
      />
      <SelectField
        items={childrenCount}
        fieldName={getFormKey(formKey, 'child_benefit_non_eligible_children')}
        label={t(
          'customer:newCustomer.form.childBenefitNonEligibleChildren.label',
        )}
      />
    </Stack>
  );
};

export default ChildrenCountForm;
