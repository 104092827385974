import { AxiosError } from 'axios';
import { ErrorMessageResponse, ErrorType } from '@/types';
import { ErrorMap } from '@/constants/errors';

export const isKnownError = (error: string): boolean => {
  return Object.values(ErrorType).includes(error as ErrorType);
};

export class ErrorResponse {
  private error: AxiosError;
  constructor(error: unknown) {
    this.error = error as AxiosError;
  }

  getStatus = () => {
    return this.error.status;
  };

  getType = () => {
    return this.getStatus()
      ? ErrorMap[this.getStatus() as keyof typeof ErrorMap] ||
          ErrorType.UnknownError
      : undefined;
  };

  getMessage = () => {
    return this.error.message;
  };

  getResponseMessage = () => {
    return (this.error.response?.data as ErrorMessageResponse)?.message;
  };
}
