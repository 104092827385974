import { Button, Typography } from '@mui/material';
import { FC } from 'react';

import style from './style';
import Icon from '@/components/ui/Icon';
import { IconType } from '@/assets/icons';

export interface TextButtonProps {
  icon: IconType;
  text?: string;
  color?: 'primary' | 'secondary' | 'error' | 'inherit';
  onClick?: () => void;
}

const TextButton: FC<TextButtonProps> = ({
  text,
  icon,
  color = 'inherit',
  onClick,
}) => {
  return (
    <Button sx={style.container} variant="text" color={color} onClick={onClick}>
      <Icon icon={icon} sx={style.icon} />
      <Typography variant="button">{text}</Typography>
    </Button>
  );
};

export default TextButton;
