import { forwardRef, useImperativeHandle, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import PersonalDetailsForm from '@/components/forms/PersonalDetailsForm';
import WorkForm from '@/components/forms/WorkForm';
import HealthInsuranceForm from '@/components/forms/HealthInsuranceForm';
import IncomeForm from '@/components/forms/IncomeForm';
import ChildrenCountForm from '@/components/forms/ChildrenCountForm';
import Stepper from '@/components/ui/Stepper';

import { cloneDeep, compact, findIndex } from 'lodash';
import { useFormikContext } from 'formik';
import { CustomerForm, CustomerFormType, EmploymentStatus } from '@/types';
import { showIf } from '@/utils/system';
import { CustomerSteps } from '@/constants/steppers';

export interface NewCustomerStepperProps {
  formType: CustomerFormType;
  onStepperEnd: () => void;
  onStepperStart: () => void;
  onFirstStep?: () => void;
}

export interface NewCustomerStepperRef {
  nextStep: () => void;
  prevStep: () => void;
}

const Steps = (formKey: CustomerFormType) => ({
  [CustomerSteps.PersonalDetails]: <PersonalDetailsForm formKey={formKey} />,
  [CustomerSteps.Work]: <WorkForm formKey={formKey} />,
  [CustomerSteps.Insurance]: <HealthInsuranceForm formKey={formKey} />,
  [CustomerSteps.Income]: <IncomeForm formKey={formKey} />,
  [CustomerSteps.Other]: <ChildrenCountForm formKey={formKey} />,
});

const NewCustomerStepper = forwardRef<
  NewCustomerStepperRef,
  NewCustomerStepperProps
>(({ onStepperEnd, onStepperStart, onFirstStep, formType }, ref) => {
  const { values } = useFormikContext<CustomerForm>();

  const { t } = useTranslation();

  const [activeStep, setActiveStep] = useState(CustomerSteps.PersonalDetails);
  const [doneSteps, setDoneSteps] = useState<string[]>([]);

  const newCustomerSteps = useMemo(() => {
    return compact([
      {
        id: CustomerSteps.PersonalDetails,
        title: t('customer:newCustomer.steps.personalDetails'),
      },
      {
        id: CustomerSteps.Work,
        title: t('customer:newCustomer.steps.work'),
      },
      showIf(
        values[formType],
        'employment_status',
        [
          EmploymentStatus.Contributions,
          EmploymentStatus.SelfEmpWithEmp,
          EmploymentStatus.EmpMdPartner,
          EmploymentStatus.SelfEmpNoEmp,
          EmploymentStatus.MiniJob,
          EmploymentStatus.CivilServant,
        ],
        {
          id: CustomerSteps.Insurance,
          title: t('customer:newCustomer.steps.insurance'),
        },
      ),
      showIf(
        values[formType],
        'employment_status',
        [
          EmploymentStatus.Contributions,
          EmploymentStatus.MiniJob,
          EmploymentStatus.CivilServant,
          EmploymentStatus.SelfEmpWithEmp,
          EmploymentStatus.EmpMdPartner,
          EmploymentStatus.SelfEmpNoEmp,
          EmploymentStatus.CivilServant,
        ],
        {
          id: CustomerSteps.Income,
          title: t('customer:newCustomer.steps.income'),
        },
      ),
      {
        id: CustomerSteps.Other,
        title: t('customer:newCustomer.steps.children'),
      },
    ]);
  }, [formType, t, values]);

  const stepIndex = findIndex(newCustomerSteps, { id: activeStep });

  const nextStep = () => {
    const nextStepIndex = stepIndex + 1;
    setDoneSteps((prev) => [...prev, newCustomerSteps[stepIndex].id]);

    if (nextStepIndex === newCustomerSteps.length) {
      onStepperEnd();
    } else {
      setActiveStep(newCustomerSteps[nextStepIndex].id as CustomerSteps);
    }
  };

  const prevStep = () => {
    const prevStepIndex = stepIndex - 1;

    setDoneSteps((prev) => {
      const prevClone = cloneDeep(prev);
      prevClone.splice(stepIndex, 1);
      return prevClone;
    });

    if (prevStepIndex < 0) {
      onStepperStart();
    } else {
      setActiveStep(newCustomerSteps[prevStepIndex].id as CustomerSteps);
      if (prevStepIndex === 0) {
        onFirstStep?.();
      }
    }
  };

  const handleSelectStep = (id: string) => {
    setActiveStep(id as CustomerSteps);
  };

  useImperativeHandle(ref, () => ({
    nextStep,
    prevStep,
  }));

  return (
    <Stepper
      active={activeStep}
      done={doneSteps}
      stepMap={Steps(formType)}
      steps={newCustomerSteps}
      onStepSelect={handleSelectStep}
    />
  );
});

export default NewCustomerStepper;
