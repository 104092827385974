import { Stack } from '@mui/material';

import useAuthStore from '@/store/auth';
import SideBar from '@/components/ui/SideBar';
import UserCard from '@/components/ui/UserCard';
import { Outlet, useNavigate } from 'react-router-dom';
import {
  SideBarAdminNavigation,
  SideBarConsultantNavigation,
} from '@/constants/sidebar';
import ScreenWrapper from '@/components/ui/ScreenWrapper';
import usePermissions from '@/hooks/usePermissions';
import { SideBarItemData } from '@/components/ui/SideBarItem';
import { getPath } from '@/utils';
import useClearState from '@/hooks/useClearState';

const HomeView = () => {
  const { user, signOut } = useAuthStore();
  const { clearAllStates } = useClearState();
  const { select } = usePermissions();

  const navigate = useNavigate();

  const handleSideBarItemClick = (item: SideBarItemData) => {
    navigate(getPath(item.path));
  };

  const sideBarItems =
    select([
      [['users', 'customers'], SideBarAdminNavigation],
      [['customers'], SideBarConsultantNavigation],
    ]) || [];

  const handleSignOut = () => {
    clearAllStates();
    signOut();
  };

  return (
    <ScreenWrapper flexDirection="row">
      <SideBar
        items={sideBarItems}
        onSideBarItemClick={handleSideBarItemClick}
        endElement={
          !!user && (
            <UserCard
              name={user.name}
              email={user.email}
              onAction={handleSignOut}
              actionIcon="Exit"
            />
          )
        }
      />
      <Stack flex={1} overflow="hidden">
        <Outlet />
      </Stack>
    </ScreenWrapper>
  );
};

export default HomeView;
