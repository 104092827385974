import { Stack, Typography } from '@mui/material';
import IconButton from '@/components/ui/IconButton';

import style from './style';
import { useTranslation } from 'react-i18next';
import { FC } from 'react';

export interface PaginationProps {
  page: number;
  total: number;
  nextPage?: () => void;
  prevPage?: () => void;
}

const Pagination: FC<PaginationProps> = ({
  page,
  total,
  nextPage,
  prevPage,
}) => {
  const { t } = useTranslation();

  return (
    <Stack sx={style.container}>
      <Typography variant="body2" color="text.secondary">
        {t('common:pagination.count', { page, total })}
      </Typography>
      <Stack sx={style.navContainer}>
        <IconButton
          iconSx={style.iconBack}
          icon="Arrow"
          disabled={page === 1}
          variant="outlined"
          onClick={prevPage}
        />
        <IconButton
          disabled={page === total}
          icon="Arrow"
          variant="outlined"
          onClick={nextPage}
        />
      </Stack>
    </Stack>
  );
};

export default Pagination;
