import { IconButton as MUIIconButton, SxProps, Theme } from '@mui/material';
import { IconType } from '@/assets/icons';
import Icon from '@/components/ui/Icon';
import { FC } from 'react';

import { SXStyleSheet } from '@/utils';
import style from './style';

export interface IconButtonProps {
  icon: IconType;
  onClick?: () => void;
  sx?: SxProps<Theme>;
  iconSx?: SxProps<Theme>;
  disabled?: boolean;
  variant?: 'icon' | 'outlined';
}

const IconButton: FC<IconButtonProps> = ({
  icon,
  onClick,
  sx,
  disabled,
  iconSx,
  variant = 'icon',
}) => {
  return (
    <MUIIconButton
      sx={SXStyleSheet.merge(sx, disabled && style.disabled, style[variant])}
      onClick={onClick}
      disabled={disabled}
    >
      <Icon sx={iconSx} icon={icon} />
    </MUIIconButton>
  );
};

export default IconButton;
