import { SxProps, Theme } from '@mui/material';
import { compact, flatten } from 'lodash';

export type SXStyleSheetType = Record<string, SxProps<Theme>>;

const create = <T extends SXStyleSheetType>(object: T): T => {
  return object;
};

const merge = (
  ...styles: (SxProps<Theme> | false | undefined)[]
): SxProps<Theme> => {
  const value = compact(flatten(styles));

  return value as SxProps<Theme>;
};

export const SXStyleSheet = {
  create,
  merge,
};

export default SXStyleSheet;
