import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';

import SelectField from '@/components/formControl/SelectField';
import TextField from '@/components/formControl/TextField';

import useTranslatedEnum from '@/hooks/useTranslatedEnum';

import {
  CustomerForm,
  CustomerFormProps,
  EmploymentStatus,
  LegalForm,
  PensionInsuranceStatus,
} from '@/types';
import { useFormikContext } from 'formik';
import { getFormKey, showIf } from '@/utils/system';
import { FC, useMemo } from 'react';
import { BBesGGroups, customerVisibilityKeys } from '@/constants/form';
import { keys, map } from 'lodash';

const WorkForm: FC<CustomerFormProps> = ({ formKey }) => {
  const { values } = useFormikContext<CustomerForm>();

  const { t } = useTranslation();
  const [employmentStatus] = useTranslatedEnum(
    'EmploymentStatus',
    EmploymentStatus,
  );
  const [legalForm] = useTranslatedEnum('LegalForm', LegalForm);
  const [pensionInsuranceStatus] = useTranslatedEnum(
    'PensionInsuranceStatus',
    PensionInsuranceStatus,
  );

  const jobTitleGroupedItems = useMemo(() => {
    return map(keys(BBesGGroups), (key) => {
      return {
        label: t(`enums:BBeSG.${key}`),
        items: BBesGGroups[key as keyof typeof BBesGGroups],
      };
    });
  }, [t]);

  return (
    <Stack gap={6} pb={10}>
      <SelectField
        fieldName={getFormKey(formKey, 'employment_status')}
        items={employmentStatus}
        label={t('customer:newCustomer.form.employmentStatus.label')}
      />
      {showIf(
        values[formKey],
        'employment_status',
        customerVisibilityKeys.jobTitle,
        <TextField
          inline
          hideErrorMessage
          fieldName={getFormKey(formKey, 'job_title')}
          label={t('customer:newCustomer.form.jobTitle.label')}
          placeholder={t('customer:newCustomer.form.jobTitle.placeholder')}
        />,
      )}
      {showIf(
        values[formKey],
        'employment_status',
        customerVisibilityKeys.jobTitleBBesG,
        <SelectField
          items={jobTitleGroupedItems}
          fieldName={getFormKey(formKey, 'job_title')}
          label={t('customer:newCustomer.form.jobTitleBBesG.label')}
        />,
      )}
      {showIf(
        values[formKey],
        'employment_status',
        customerVisibilityKeys.legalForm,
        <SelectField
          fieldName={getFormKey(formKey, 'legal_form')}
          label={t('customer:newCustomer.form.legalForm.label')}
          items={legalForm}
        />,
      )}
      {showIf(
        values[formKey],
        'employment_status',
        customerVisibilityKeys.employeeCount,
        <TextField
          inline
          hideErrorMessage
          fieldName={getFormKey(formKey, 'employee_count')}
          label={t('customer:newCustomer.form.employeeCount.label')}
          placeholder={t('customer:newCustomer.form.employeeCount.placeholder')}
        />,
      )}
      {showIf(
        values[formKey],
        'employment_status',
        customerVisibilityKeys.revenueAndProfit,
        <TextField
          inline
          hideErrorMessage
          fieldName={getFormKey(formKey, 'revenue')}
          label={t('customer:newCustomer.form.revenue.label')}
          placeholder={t('customer:newCustomer.form.revenue.placeholder')}
        />,
      )}
      {showIf(
        values[formKey],
        'employment_status',
        customerVisibilityKeys.revenueAndProfit,
        <TextField
          inline
          hideErrorMessage
          fieldName={getFormKey(formKey, 'profit')}
          label={t('customer:newCustomer.form.profit.label')}
          placeholder={t('customer:newCustomer.form.profit.placeholder')}
        />,
      )}
      {showIf(
        values[formKey],
        'employment_status',
        customerVisibilityKeys.legalForm,
        <SelectField
          fieldName={getFormKey(formKey, 'pension_insurance_status')}
          items={pensionInsuranceStatus}
          label={t('customer:newCustomer.form.pensionInsuranceStatus.label')}
        />,
      )}
    </Stack>
  );
};

export default WorkForm;
