import { SXStyleSheet } from '@/utils';
import { tableCellClasses } from '@mui/material';

const style = SXStyleSheet.create({
  row: {
    height: '72px',
    cursor: 'pointer',
    [`.${tableCellClasses.root}`]: {
      padding: 0,
      px: 6,
      borderColor: 'divider',
    },
    '&:hover': {
      backgroundColor: 'primary.light',
    },
  },
});

export default style;
